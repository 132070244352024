<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("contentFiltering.infoTitle") }}</h4>
					<p v-html="$t('contentFiltering.infoBody')"></p>
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("contentFiltering.list") }}</h4>
				</md-card-header>
				<md-card-content>
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click="$router.push({ name: 'Add Content Filtering' })"
							v-if="hasPermission('marketplace:content_filtering_add')">
							{{ $t("contentFiltering.add") }}
							<md-icon>add</md-icon>
						</md-button>
					</div>
					<md-table
						:md-sort-fn="customSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort.sync="currentSort"
						:value="queriedData"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>
							<md-field>
								<md-input
									v-model="filters.query"
									:placeholder="$t('general.search_records')"
									class="mb-3"
									clearable
									style="width: 200px"
									type="search">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell :md-label="$t('fields.device.id')" md-sort-by="device_id"
								>{{ item.device_id }}
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.device.service_entry')" md-sort-by="service_entry"
								>{{ item.service_entry }}
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.device.label')" md-sort-by="device_name"
								>{{ item.device_name }}
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.actions.label')">
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-warning"
									@click.native="handleEdit(item.device_id)"
									v-if="hasPermission('marketplace:content_filtering_edit')">
									<md-tooltip md-direction="top"
										><p v-html="$t('contentFiltering.editTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>edit</md-icon>
										<div style="font-size: xx-small">Edit</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-simple md-just-icon md-danger"
									@click.native="handleDelete(item)"
									v-if="hasPermission('marketplace:content_filtering_delete')">
									<md-tooltip md-direction="top"
										><p v-html="$t('contentFiltering.deleteTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>delete</md-icon>
										<div style="font-size: xx-small">Delete</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
						class="pagination-no-border pagination-success">
					</pagination>
				</md-card-actions>
			</md-card>
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>motion_photos_pause</md-icon>
					</div>
					<h4 class="title">{{ $t("contentFiltering.suspended") }}</h4>
				</md-card-header>
				<md-card-content>
					<md-table
						:md-sort-fn="customSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort.sync="currentSort"
						:value="suspendedData"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<!-- <md-field>
								<md-input
								v-model="filters.query"
								:placeholder="$t('general.search_records')"
								class="mb-3"
								clearable
								style="width: 200px"
								type="search">
								</md-input>
							</md-field> -->
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell :md-label="$t('fields.device.id')" md-sort-by="device_id"
								>{{ item.device_id }}
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.device.service_entry')" md-sort-by="service_entry"
								>{{ item.service_entry }}
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.device.label')" md-sort-by="device_name">{{
								item.device_name
							}}</md-table-cell>
							<md-table-cell>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<!-- We have to leave this empty div as separator -->
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
						class="pagination-no-border pagination-success">
					</pagination>
				</md-card-actions>
			</md-card>
			<modal v-if="flgShowTemplateTextModal" @close="hideTemplateTextModal">
				<template slot="header">
					<h4 class="modal-title">{{ templateModal.title }}</h4>
					<md-button
						class="md-simple md-just-icon md-round modal-default-button"
						@click="hideTemplateTextModal">
						<md-icon>clear</md-icon>
					</md-button>
				</template>

				<template slot="body">
					<pre class="modal-code">{{ templateModal.text }}</pre>
				</template>

				<template slot="footer">
					<md-button class="md-info md-round" @click="editTemplate()">Edit</md-button>
					<md-button class="md-info md-round" @click="hideTemplateTextModal">Close</md-button>
				</template>
			</modal>
		</div>
	</div>
</template>

<script>
import { Modal, Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import TokenService from "@/services/token";
import { swalUtils } from "@/mixins/mixin";
import { mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	mixins: [swalUtils],
	components: {
		Pagination,
		Modal,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}
			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			if (!this.searchedData || !this.tableData) {
				return 0;
			}
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
		suspendedData() {
			let result = this.suspendedTableData;
			return result;
		},
	},
	data() {
		return {
			flgShowTemplateTextModal: false,
			templateModal: {
				title: "",
				text: "",
				template_id: 0,
			},
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: [this.$t("fields.description.label")],
			searchQuery: "",
			propsToSearch: ["description"],
			tableData: [],
			suspendedTableData: [],
			searchedData: [],
			fuseSearch: null,
			filters: {
				query: "",
			},
		};
	},
	methods: {
		searchByQuery(results) {
			if (!results || this.filters.query === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["device_name", "device_id", "service_entry"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});
			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		handleEdit(device_id) {
			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/content-filtering/sso", {
					device_id: device_id,
				})
				.then((response) => {
					// handle
					// response is
					// console.log("Response: ", response.data.sso)
					let sso = response.data.sso;
					// urlencoding sso
					//sso = encodeURIComponent(sso);
					// console.log("Sso: ", sso)

					// now we have to set the URL "https://cloud.flashstart.com/?auth_token=sso" in the iframe
					// now we have to open in a new window https://cloud.flashstart.com/?auth_token=sso
					window.open("https://cloud.flashstart.com/?auth_token=" + sso, "_blank");
					// this.openIframe(sso);
				})
				.catch((error) => {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							html:
								this.$t("licence.activate.error.content") +
								"<code>" +
								error.response.data.error +
								"</code>",
							footer: this.$t("responses.error.footer"),
							showCloseButton: false,
							icon: "error",
							allowOutsideClick: false,
							backdrop: `rgba(150,0,0,0.4)`,
						});
					}
				});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		handleDelete(item) {
			this.swalWarningDialog(
				this.$t("dialogs.delete_content_filtering_confirmation.title"),
				this.$t("dialogs.delete_content_filtering_confirmation.content", { subject: item.description }),
				this.$t("dialogs.delete_content_filtering_confirmation.ok")
			).then((result) => {
				if (result.value) {
					let req = {
						device_id: item.device_id,
					};
					let userData = TokenService.getUser();
					this.swalShowLoader();
					evoapi
						.post("/customers/" + userData.customerId + "/content-filtering/deactivate", req)
						.then((response) => {
							this.deleteRow(item);
							this.filters.query = ""; // Resetta il filtro di ricerca
							this.swalSuccessDialog(
								this.$t("responses.deleted_success.title"),
								this.$t("responses.deleted_success.content", { subject: item.description })
							).then((result) => {
								// Ricarica la pagina quando l'utente clicca sul pulsante di conferma
								if (result.value) {
									// window.location.reload();
									this.reloadTableData();
									this.reloadSuspendendTableData();
								}
							});
						})
						.catch((error) => {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("contentFiltering.error.correctRouter"),
									footer: this.$t("responses.error.footer"),
								});
							}
						});
				}
			});
		},
		openIframe(sso) {
			let userData = TokenService.getUser();
			this.$router.push({
				name: "FlashStart Web Panel",
				params: {
					sso: sso,
				},
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.device_id === item.device_id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
				// Aggiorna queriedData o ricarica i dati dal server se necessario
				this.reloadTableData(); // Chiamalo solo se vuoi ricaricare i dati dal server
			}
		},
		async reloadTableData() {
			// Get data from API
			let userData = TokenService.getUser();
			let response = await evoapi.get("/customers/" + userData.customerId + "/content-filtering/list");
			this.tableData = response.data;
			this.searchedData = this.tableData;
		},
		async reloadSuspendendTableData() {
			// Get data from API
			let userData = TokenService.getUser();
			let response = await evoapi.get(
				"/customers/" + userData.customerId + "/content-filtering/list?suspended=1"
			);
			this.suspendedTableData = response.data;
		},
	},
	mounted() {
		this.reloadTableData();
		this.reloadSuspendendTableData();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;
				results = this.searchByQuery(results);
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style lang="css" scoped>
.md-table-head {
	text-align: center;
	font-size: 20px;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.md-table-cell {
	border-right: 1px solid #ccc; /* Aggiungi un bordo a destra di ogni cella */
	text-align: center; /* Assicurati che il testo sia centrato */
}

.md-table-cell:last-child {
	border-right: none; /* Rimuovi il bordo a destra dell'ultima cella */
}

.md-table-head {
	text-align: center;
	font-size: 20px;
	border-right: 1px solid #ccc; /* Aggiungi un bordo anche agli header */
}

.md-table-head:last-child {
	border-right: none;
}

/* Assicurati che le righe siano ben separate */
.md-table-row {
	border-bottom: 1px solid #ccc;
}

/* Stile per i contenitori delle celle e delle intestazioni */
.centered-content {
	text-align: center;
}

/* Stile per le intestazioni della tabella */
.centered-header th {
	text-align: center !important;
}
.md-table-head {
	background-color: #f0f0f0; /* Grigio chiaro */
	text-align: center;
	font-size: 20px;
	border-right: 1px solid #ccc;
}
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.modal-code {
	text-align: left;
	font-family: "Courier New", Courier, monospace;
	font-size: 12px;
	overflow: scroll;
	height: 500px;
}

code:hover {
	font-weight: bold;
	text-decoration: underline;
	font-size: 105%;
	color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	background-color: #007bff; /* un leggero grigio come esempio, personalizza a tuo piacimento */
	background: linear-gradient(45deg, #006ba6, #d5419e);
}
code {
	border-radius: 4px;
	transition: all 0.3s; /* 0.3 secondi come esempio, personalizza a tuo piacimento */
	cursor: pointer;
}
</style>
