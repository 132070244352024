<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<div>
				<h5 class="info-text">
					{{ $t("wizard_sdwan.step_1_provisioning.title") }}
				</h5>
				<div class="md-layout">
					<div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100">
						<ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>description</md-icon>
								<label>{{ $t("fields.description.label") }}</label>
								<md-input v-model="description" type="text"> </md-input>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>
						<md-field>
							<md-icon>device_hub</md-icon>
							<label>SDWAN type</label>
							<md-select v-model="sdwanType" name="sdwanType" :disabled="isDisabled">
								<md-option value="1">aggregation (ECMP mode) / failover (SDWAN 2.0)</md-option>
								<md-option value="0">failover (SDWAN 1.0) [DEPRECATED]</md-option>
							</md-select>
						</md-field>
						<!-- </div> -->
						<!-- <div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100" v-if="sdwanType === '1'"> -->
						<span v-if="sdwanType === '1'">
							<md-field>
								<md-icon>public</md-icon>
								<label>Concentrator</label>
								<md-select v-model="selectedConcentrator" :disabled="isConcentratorDisabled">
									<!-- Mostra questa opzione solo se ci sono più di un concentratore -->
									<md-option value="">Seleziona un concentratore</md-option>
									<md-option
										v-for="concentrator in sdwanConcentrators"
										:key="concentrator.ID"
										:value="concentrator.concentratorID_regionID">
										{{ concentrator.Name }} <br />(region: {{ concentrator.RegionName }} | location:
										{{ concentrator.RegionLocation }})
									</md-option>
								</md-select>
							</md-field>
							<md-field>
								<md-icon>public</md-icon>
								<label>Elastic IPs</label>
								<!-- Displaying the sdwan_cidr_addr array if isConcentratorDisabled -->
								<md-field
									v-if="sdwanCidrAddr && sdwanCidrAddr.length && isConcentratorDisabled"
									class="md-layoud">
									<ul>
										<li v-for="addr in sdwanCidrAddr" :key="addr">{{ addr }}</li>
									</ul>
								</md-field>
								<md-select
									v-model="selectedElasticIp"
									:disabled="isConcentratorDisabled"
									v-if="!isConcentratorDisabled">
									<md-option value="">Auto Assign IP</md-option>
									<md-option v-for="ip in elasticIps" :key="ip.elastic_ip_id" :value="ip.subnet">
										{{ ip.subnet }}
									</md-option>
								</md-select>
							</md-field>
							<!-- <ValidationProvider name="lan_network" rules="required|subnet" v-slot="{ passed, failed }">
								<md-field
									:class="[
										{ 'md-error': failed },
										{ 'md-valid': passed },
										{ 'md-form-group': true },
									]">
									<md-icon>device_hub</md-icon>
									<md-autocomplete
										v-if="uniqueNetworks && uniqueNetworks.length"
										v-model="lan_network"
										:md-options="uniqueNetworks">
										<label>LAN Network</label>
									</md-autocomplete>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider> -->
							<template>
								<ValidationProvider name="lan_interfaces" rules="required" v-slot="{ passed, failed }">
									<md-field
										:class="[
											{ 'md-error': failed },
											{ 'md-valid': passed },
											{ 'md-form-group': true },
										]">
										<md-icon>device_hub</md-icon>
										<label>LAN Interfaces (Interface list : LAN - mandatory)</label>
										<div v-if="uniqueInterfaces.length > 0" class="checkbox-container">
											<div
												v-for="interfaceName in uniqueInterfaces"
												:key="interfaceName"
												class="checkbox-item">
												<md-checkbox v-model="selectedInterfaces" :value="interfaceName">
													{{ interfaceName }}
												</md-checkbox>
											</div>
										</div>
										<div v-else>Caricamento interfacce in corso...</div>
										<slide-y-down-transition>
											<md-icon class="error" v-show="failed">close</md-icon>
										</slide-y-down-transition>
										<slide-y-down-transition>
											<md-icon class="success" v-show="passed">done</md-icon>
										</slide-y-down-transition>
									</md-field>
								</ValidationProvider>
							</template>
						</span>
					</div>
				</div>
			</div>
		</form>
	</ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { integer } from "vee-validate/dist/rules";
import swal from "sweetalert2";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);
extend("integer", integer);

export default {
	created() {
		// console.log("FirstProvisionedStep created");
		// console.log("Initial lanInterfaces:", this.lanInterfaces);
		// console.log("Initial selectedInterfaces:", this.selectedInterfaces);
	},
	components: {
		SlideYDownTransition,
	},
	props: ["addresses", "sdwanRegions", "sdwanConcentrators", "lanInterfaces", "sdwanConnection"],
	data() {
		//console.log(this.$route.params.device_name);
		return {
			description: "SDWAN - " + this.$route.params.device_name,
			sdwanType: "1",
			lan_network: "203.0.113.80/32", // fake private IP address for retro compatibility, will not be used
			sdwanTypeDisabled: false,
			selectedNetwork: "",
			uniqueNetworks: [],
			selectedRegion: "",
			selectedConcentrator: "",
			elasticIps: [],
			selectedElasticIp: "",
			sdwanCidrAddr: [],
			contract_id: "",
			interfaces: ["brLAN", "ether1", "ether2", "pppoe-out1", "MANAGEMENT"],
			selectedInterfaces: [],
		};
	},
	watch: {
		lanInterfaces: {
			immediate: true,
			handler(newVal) {
				// console.log("lanInterfaces changed:", newVal);
				this.selectedInterfaces = [...newVal];
				// console.log("selectedInterfaces updated:", this.selectedInterfaces);
			},
		},
		selectedConcentrator(concentratorID_regionID) {
			// console.log("concentratorID_regionID-watch: ", concentratorID_regionID);
			if (concentratorID_regionID) {
				// logging concentratorID_regionID
				// console.log("concentratorID_regionID: ", concentratorID_regionID);
				// creating var RegionID from concentratorID_regionID
				let RegionID = concentratorID_regionID.split("_")[1];
				// console.log("RegionID: ", RegionID);
				this.fetchElasticIps(RegionID);
			}
		},
		sdwanConcentrators: function (val) {
			// console.log("sdwanConcentrators: ", val);
			// setting selectedConcentrator to first element of sdwanConcentrators if sdwanConcentrators is not empty and selectedConcentrator is empty
			if (val.length > 0 && !this.selectedConcentrator && !this.concentrator_id) {
				this.selectedConcentrator = val[0].concentratorID_regionID;
			}
			// if this.concentrator_id is not empty then set selectedConcentrator to this.concentrator_id
			if (this.concentrator_id) {
				this.selectedConcentrator = this.concentrator_id + "_" + this.region_id;
				if (this.elastic_ip) {
					// console.log("this.elastic_ip: ", this.elastic_ip);
					this.elasticIps.push(this.elastic_ip);
					this.selectedElasticIp = this.elastic_ip;
				}
			}
			// if this.concentrator_id is not empty then set the selectbox to disabled
			// TODO
		},
		elasticIps: function (val) {
			// console.log("elasticIps: ", val);
			// setting selectedConcentrator to first element of sdwanConcentrators if sdwanConcentrators is not empty and selectedConcentrator is empty
			// if this.elastic_ip is not empty then:
			// 1. add this.elastic_ip to this.elasticIps
			// 2. set selectedElasticIp to this.elastic_ip
			if (this.elastic_ip) {
				this.selectedElasticIp = this.elastic_ip;
			}
		},
		uniqueNetworks: function (val) {
			// setting lan_network to first element of uniqueNetworks if uniqueNetworks is not empty and lan_network is empty
			if (val && val.length > 0 && !this.lan_network) {
				this.lan_network = val[0];
			}
		},
		addresses: {
			handler: function (val) {
				this.uniqueNetworks = [...new Set(val.map((item) => item.network + "/" + item.address.split("/")[1]))];
			},
			deep: true,
		},
		// sdwanType: function (val) {
		//   if (val === '0') {
		//     this.sdwanTypeDisabled = true;
		//     this.lan_network = '';
		//   } else {
		//     this.sdwanTypeDisabled = false;
		//   }
		// }
	},
	computed: {
		isDisabled() {
			return this.sdwanTypeDisabled;
		},
		isConcentratorDisabled() {
			// Disabilita il campo di selezione se contract è già definito
			// logging this.concentrator_id
			// console.log("this.contract_id: ", this.contract_id);
			return !!this.contract_id;
		},
		uniqueInterfaces() {
			const addressInterfaces = this.addresses.map((address) => address.interface);
			const allInterfaces = [...new Set([...addressInterfaces, ...this.lanInterfaces])];

			// Sort the interfaces by name
			allInterfaces.sort((a, b) => a.localeCompare(b));

			// console.log("Computed uniqueInterfaces:", allInterfaces);
			return allInterfaces;
		},
	},
	mounted() {
		// console.log("sdwanConnection: ", this.sdwanConnection);
		this.sdwanCidrAddr = this.sdwanConnection.sdwan_cidr_addr;
	},
	methods: {
		validate() {
			// Verifica se lan_network è vuoto e verifichiamo se lan_network è in IP in questo formato xxx.xxx.xxx.xxx/xx
			// validate only if sdwanType is 1
			if (this.sdwanType === "1") {
				if (!this.lan_network || this.lan_network.split("/").length !== 2) {
					swal.fire({
						icon: "error",
						title: this.$t("wizard_sdwan.step_1_provisioning.error_title"),
						text: this.$t("wizard_sdwan.step_1_provisioning.error_text"),
					});
					return Promise.resolve(false); // Ritorna una promessa risolta con false
				}
				// checking if concentrator is selected
				if (!this.selectedConcentrator) {
					swal.fire({
						icon: "error",
						title: this.$t("wizard_sdwan.step_1_provisioning.concentrator_error_title"),
						text: this.$t("wizard_sdwan.step_1_provisioning.error_text_concentrator"),
					});
					return Promise.resolve(false); // Ritorna una promessa risolta con false
				}
			}

			return this.$refs.form.validate().then((res) => {
				if (res) {
					this.$emit("on-validated", {
						...this.$data,
						selectedInterfaces: this.selectedInterfaces,
					});
				}
				return res;
			});
		},
		getUniqueNetworks() {
			const networks = this.addresses.map((address) => address.network);
			this.uniqueNetworks = [...new Set(networks)]; // rimuove i duplicati
		},
		fetchElasticIps(regionID) {
			let userData = TokenService.getUser();

			// se this.elastic_ip è vuoto allora nei parametri notAssociated: true altrimenti notAssociated: false
			let notAssociated = true;
			if (this.elastic_ip) {
				notAssociated = false;
			}
			// Utilizzo di evoapi per effettuare la chiamata API
			evoapi
				.get(`/customers/${userData.customerId}/elasticIp/listIps/${regionID}`, {
					params: {
						onlyFree: false,
						onlyMine: true,
						notAssociated: notAssociated,
					},
				})
				.then((response) => {
					// logging response
					// console.log("response: ", response);
					// logging regionID
					// console.log("regionID: ", regionID);
					// Filtra gli indirizzi IP per la regione corrispondente
					this.elasticIps = response.data.ips.filter((ip) => ip.region_id == regionID);
					// logging elasticIps
					// console.log("elasticIps: ", this.elasticIps);
				})
				.catch((error) => {
					// Gestione dell'errore
					// console.Log("No available IP Addresses", error);
					// inizializzo array vuoto
					this.elasticIps = [];
				});
		},
	},
};
</script>
<style scoped>
.checkbox-container {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	margin-top: 40px;
	margin-left: 14px;
}

.checkbox-item {
	flex: 0 1 auto;
}

.md-checkbox {
	margin: 0;
}
</style>
