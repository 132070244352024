<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("sdwan.infoTitle") }}</h4>
					<p v-html="$t('sdwan.infoBody')"></p>
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("sdwan.list") }}</h4>
					<br />
					<div class="text-right">
						<md-button class="md-info md-round" @click="reloadTableData()">
							<p v-html="$t('device.refresh')"></p>
							<md-icon>refresh</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:md-sort-fn="customSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort.sync="currentSort"
						:value="queriedData"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									v-model="filters.query"
									:placeholder="$t('general.search')"
									class="mb-3"
									clearable
									style="width: 200px"
									type="search">
								</md-input>
							</md-field>
						</md-table-toolbar>
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Contract ID" md-sort-by="contract_id" style="width: 2%"
								>{{ item.contract_id }}
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.status.label')" md-sort-by="status" style="width: 3%">
								<div v-if="item.status == 'OK'">
									<span class="status-ok" @click="goToSdwanDetails(item.contract_id)"></span>
									<md-tooltip md-direction="top">
										<p v-html="$t('sdwan.statusOkTooltip')"></p>
									</md-tooltip>
								</div>
								<div v-else-if="item.status == 'WARNING'">
									<span class="status-warning" @click="goToSdwanDetails(item.contract_id)"></span>
									<md-tooltip md-direction="top">
										<p v-html="$t('sdwan.statusWarningTooltip')"></p>
									</md-tooltip>
								</div>
								<div v-else-if="item.status == 'KO'">
									<span class="status-ko" @click="goToSdwanDetails(item.contract_id)"></span>
									<md-tooltip md-direction="top">
										<p v-html="$t('sdwan.statusKoTooltip')"></p>
									</md-tooltip>
								</div>
								<div v-else>
									<p v-html="$t('sdwan.statusNotFound')"></p>
								</div>
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.description.label')" md-sort-by="description">
								<code class="md-primary md-round" @click="goToSdwanDetails(item.contract_id)">
									{{ item.description }}
									<md-icon>info</md-icon>
								</code>
							</md-table-cell>
							<md-table-cell md-label="SDWAN cidr" md-sort-by="sdwan_cidr">
								<b>{{ $t("sdwan.elasticIp.mainElasticIp") }}</b
								>: {{ item.sdwan_cidr }}
								<br />
								<div v-if="item.elastic_ip_id.Int64 > 0">
									<small>
										<code
											class="md-primary md-round"
											@click="goToElasticIp(item.elastic_ip_id.Int64)">
											Elastic IP id {{ item.elastic_ip_id.Int64 }}
											<md-icon>open_in_browser</md-icon>
										</code>
									</small>
								</div>
								<div v-if="item.sdwan_cidr_addr_str">
									<br />
									<b>{{ $t("sdwan.elasticIp.elasticIpList") }}</b
									>: {{ item.sdwan_cidr_addr_str }}
								</div>
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.actions.label')"
								class="centered-content bordered-cell">
								<!-- Phone view -->
								<actions class="hide-on-large" style="margin-right: 14px">
									<!-- Add IP -->
									<md-button
										v-if="hasPermission('marketplace:sdwan_edit')"
										class="md-simple"
										@click.native="handleAddIP(item.contract_id)">
										<md-tooltip md-direction="top"
											><p v-html="$t('sdwan.addIPTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>add_circle</md-icon>
											<div style="font-size: xx-small">Add IP</div>
										</div>
									</md-button>
									<!-- Del IP -->
									<md-button
										v-if="hasPermission('marketplace:sdwan_edit')"
										class="md-simple md-danger"
										@click.native="handleRemoveIP(item.contract_id, item.sdwan_cidr_addr)">
										<md-tooltip md-direction="top"
											><p v-html="$t('sdwan.removeIPTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>remove_circle</md-icon>
											<div style="font-size: xx-small">DEL IP</div>
										</div>
									</md-button>
									<!-- Redeploy -->
									<md-button
										v-if="hasPermission('marketplace:sdwan_redeploy')"
										class="md-simple md-just-icon md-info"
										@click.native="handleRedeploy(item.contract_id)">
										<md-tooltip md-direction="top"
											><p v-html="$t('sdwan.redeployTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>refresh</md-icon>
											<div style="font-size: xx-small">Redeploy</div>
										</div>
									</md-button>
									<!-- Shield -->
									<md-button
										v-if="
											item.concentrator_id == 3 && hasPermission('marketplace:sdwan_shield_add')
										"
										:class="{
											'md-simple': true,
											'md-danger': !item.token_sso,
											'md-success': item.token_sso,
										}"
										@click="handleShield(item.contract_id, item.token_sso)">
										<md-tooltip md-direction="top"
											><p
												v-html="
													item.token_sso
														? $t('uania.shield.tooltip.iframe')
														: $t('uania.shield.tooltip.toActivate')
												"></p>
										</md-tooltip>
										<div class="icon-with-label">
											<md-icon>security</md-icon>
											<div style="font-size: xx-small">SHIELD</div>
										</div>
									</md-button>
									<!-- Edit -->
									<md-button
										v-if="hasPermission('marketplace:sdwan_edit')"
										class="md-simple md-warning"
										@click.native="handleEdit(item.contract_id)">
										<md-tooltip md-direction="top">
											<p v-html="$t('sdwan.editTooltip')"></p>
										</md-tooltip>
										<div class="icon-with-label">
											<md-icon>edit</md-icon>
											<div style="font-size: xx-small">Edit</div>
										</div>
									</md-button>
									<!-- Delete -->
									<md-button
										v-if="hasPermission('marketplace:sdwan_delete')"
										class="md-simple md-danger"
										@click.native="handleDelete(item)">
										<md-tooltip md-direction="top"
											><p v-html="$t('sdwan.deleteTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>delete</md-icon>
											<div style="font-size: xx-small">Delete</div>
										</div>
									</md-button>
								</actions>

								<!-- Desktop version -->
								<div>
									<div>
										<!-- Add IP -->
										<md-button
											v-if="hasPermission('marketplace:sdwan_edit')"
											class="md-just-icon md-simple md-medium-hide spacing-both"
											@click.native="handleAddIP(item.contract_id)">
											<md-tooltip md-direction="top"
												><p v-html="$t('sdwan.addIPTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>add_circle</md-icon>
												<div style="font-size: xx-small">Add IP</div>
											</div>
										</md-button>
										<!-- Del IP -->
										<md-button
											v-if="hasPermission('marketplace:sdwan_edit')"
											class="md-simple md-just-icon md-danger md-medium-hide spacing-both"
											@click.native="handleRemoveIP(item.contract_id, item.sdwan_cidr_addr)">
											<md-tooltip md-direction="top"
												><p v-html="$t('sdwan.removeIPTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>remove_circle</md-icon>
												<div style="font-size: xx-small">Del IP</div>
											</div>
										</md-button>
									</div>
									<div>
										<!-- Redeploy -->
										<md-button
											v-if="hasPermission('marketplace:sdwan_edit')"
											class="md-simple md-just-icon md-info md-medium-hide spacing-both"
											@click.native="handleRedeploy(item.contract_id)">
											<md-tooltip md-direction="top"
												><p v-html="$t('sdwan.redeployTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>refresh</md-icon>
												<div style="font-size: xx-small">Redeploy</div>
											</div>
										</md-button>
										<!-- Shield -->
										<!-- Utilizzavamo in controllo su v-if="shieldAvailable" ma lo abbiamo dismesso in favore del controllo sul item.concentrator_id==3 -->
										<md-button
											v-if="
												item.concentrator_id == 3 &&
												hasPermission('marketplace:sdwan_shield_add')
											"
											:class="{
												'md-simple': true,
												'md-just-icon': true,
												'md-medium-hide': true,
												'spacing-both': true,
												'md-danger': !item.token_sso,
												'md-success': item.token_sso,
											}"
											@click="handleShield(item.contract_id, item.token_sso)">
											<md-tooltip md-direction="top"
												><p
													v-html="
														item.token_sso
															? $t('uania.shield.tooltip.iframe')
															: $t('uania.shield.tooltip.toActivate')
													"></p>
											</md-tooltip>
											<div class="icon-with-label">
												<md-icon>security</md-icon>
												<div style="font-size: xx-small">SHIELD</div>
											</div>
										</md-button>
										<!-- Edit -->
										<md-button
											v-if="hasPermission('marketplace:sdwan_edit')"
											class="md-simple md-just-icon md-warning md-medium-hide spacing-both"
											@click.native="handleEdit(item.contract_id)">
											<md-tooltip md-direction="top">
												<p v-html="$t('sdwan.editTooltip')"></p>
											</md-tooltip>
											<div class="icon-with-label">
												<md-icon>edit</md-icon>
												<div style="font-size: xx-small">Edit</div>
											</div>
										</md-button>
										<!-- Delete -->
										<md-button
											v-if="hasPermission('marketplace:sdwan_delete')"
											class="md-simple md-just-icon md-danger md-medium-hide spacing-both"
											@click.native="handleDelete(item)">
											<md-tooltip md-direction="top"
												><p v-html="$t('sdwan.deleteTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>delete</md-icon>
												<div style="font-size: xx-small">Delete</div>
											</div>
										</md-button>
									</div>
								</div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
						class="pagination-no-border pagination-success">
					</pagination>
				</md-card-actions>
			</md-card>
			<modal v-if="flgShowTemplateTextModal" @close="hideTemplateTextModal">
				<template slot="header">
					<h4 class="modal-title">{{ templateModal.title }}</h4>
					<md-button
						class="md-simple md-just-icon md-round modal-default-button"
						@click="hideTemplateTextModal">
						<md-icon>clear</md-icon>
					</md-button>
				</template>

				<template slot="body">
					<pre class="modal-code">{{ templateModal.text }}</pre>
				</template>

				<template slot="footer">
					<md-button class="md-info md-round" @click="editTemplate()">Edit</md-button>
					<md-button class="md-info md-round" @click="hideTemplateTextModal">Close</md-button>
				</template>
			</modal>
		</div>
	</div>
</template>

<script>
import { Modal, Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import TokenService from "@/services/token";
import { swalUtils } from "@/mixins/mixin";
import { mapGetters } from "vuex";
import Actions from "../../../components/Actions.vue";

export default {
	name: "PaginatedTable",
	mixins: [swalUtils],
	components: {
		Pagination,
		Actions,
		Modal,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}
			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			if (!this.searchedData || !this.tableData) {
				return 0;
			}
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			shieldAvailable: false,
			flgShowTemplateTextModal: false,
			templateModal: {
				title: "",
				text: "",
				template_id: 0,
			},
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: [this.$t("fields.description.label"), "SDWAN cidr"],
			searchQuery: "",
			propsToSearch: ["Description", "Contract ID", "SDWAN cidr"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			filters: {
				query: "",
			},
		};
	},
	methods: {
		goToElasticIp(elastic_ip_id) {
			this.$router.push({
				name: "List Elastic IPs",
				query: { search: String(elastic_ip_id) },
			});
		},
		goToSdwanDetails(sdwan_contract_id) {
			// nuova pagina
			this.$router.push({
				name: "SDWAN Details",
				params: { sdwan_contract_id: sdwan_contract_id },
			});
		},
		hideTemplateTextModal() {
			this.flgShowTemplateTextModal = false;
		},
		handleEdit(contract_id) {
			let userData = TokenService.getUser();
			evoapi
				.get("/customers/" + userData.customerId + "/sdwan/config/" + contract_id)
				.then((response) => {
					if (response.data.device_id) {
						this.$router.push({
							name: "Edit Provisioned SDWAN",
							params: {
								pageTitle: "Edit SDWAN",
								sdwanConnection: response.data,
								device_id: response.data.device_id,
							},
						});
					} else {
						this.$router.push({
							name: "Edit SDWAN",
							params: { pageTitle: "Edit SDWAN", sdwanConnection: response.data },
						});
					}
				})
				.catch(function (error) {
					this.swalBasicErrorDialog();
				});
		},
		handleAddIP(contract_id) {
			let userData = TokenService.getUser();

			Swal.fire({
				title: this.$t("dialogs.loading.title"),
				html: this.$t("dialogs.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
					// Recupera gli indirizzi IP liberi dall'API
					evoapi
						.get("/customers/" + userData.customerId + "/elasticIp/listIps/0?onlyMine=true")
						.then((response) => {
							Swal.close(); // Chiudi la notifica di attesa

							// Trova la region_name degli IP assegnati al contratto SDWAN 25
							let sdwanRegion = null;
							for (let ip of response.data.ips) {
								if (ip.sdwan_contract_id.Valid && ip.sdwan_contract_id.Int64 === contract_id) {
									sdwanRegion = ip.region_name;
									break;
								}
							}

							const freeIps = [];
							// Filtra gli IP liberi nella stessa regione del contratto SDWAN
							for (let ip of response.data.ips) {
								if (!ip.sdwan_contract_id.Valid && ip.region_name === sdwanRegion) {
									freeIps.push(ip);
								}
							}

							// Se non ci sono indirizzi IP liberi, mostra un messaggio e termina
							if (freeIps.length === 0) {
								Swal.fire({
									title: this.$t("sdwan.elasticIp.errorTitle"),
									html: this.$t("sdwan.elasticIp.noElasticIp"),
									icon: "warning",
									showCancelButton: true,
									customClass: {
										confirmButton: "md-button md-success",
										cancelButton: "md-button md-danger btn-fill",
									},
									cancelButtonText: this.$t("general.cancel"),
									buttonsStyling: false,
								});
								return;
							}
							// Mostra un dropdown list con gli indirizzi IP liberi
							Swal.fire({
								title: this.$t("sdwan.elasticIp.selectIp"),
								input: "select",
								inputOptions: freeIps.reduce((options, ip) => {
									options[ip.elastic_ip_id] = `${ip.subnet} (${ip.region_name})`;
									return options;
								}, {}),
								inputPlaceholder: this.$t("sdwan.elasticIp.selectIp"),
								showCancelButton: true,
								inputValidator: (value) => {
									if (!value) {
										return this.$t("sdwan.elasticIp.selectOneIp");
									}
								},
							}).then((result) => {
								if (result.isConfirmed) {
									let selectedIpId = result.value;

									Swal.fire({
										title: this.$t("dialogs.loading.title"),
										html: this.$t("dialogs.loading.content"),
										timerProgressBar: true,
										allowOutsideClick: false,
										backdrop: `rgba(130,0,160,0.4)`,
										didOpen: () => {
											Swal.showLoading();
											// Chiamata API per aggiungere il nuovo IP
											evoapi
												.post("/customers/" + userData.customerId + "/sdwan/add-ip", {
													SWANContractID: parseInt(contract_id),
													ID: parseInt(selectedIpId),
												})
												.then((response) => {
													Swal.close(); // Chiudi la notifica di attesa
													// Mostra un messaggio di successo
													Swal.fire("Success", this.$t("sdwan.elasticIp.addedIp"), "success");
													// Ricarica i dati della tabella per riflettere il nuovo IP
													this.reloadTableData();
												})
												.catch((error) => {
													this.swalBasicErrorDialog();
												});
										},
									});
								}
							});
						})
						.catch((error) => {
							// console.log(error);
							this.swalBasicErrorDialog();
						});
				},
			});
		},
		handleRemoveIP(contract_id, sdwan_cidr_addr) {
			const ipAddresses = sdwan_cidr_addr;

			if (ipAddresses.length <= 1) {
				Swal.fire(
					this.$t("dialogs.sdwan_remove_ip.single_ip_error.title"),
					this.$t("dialogs.sdwan_remove_ip.single_ip_error.text"),
					"error"
				);
				return;
			}

			// Configura le opzioni della select box
			const ipOptions = ipAddresses.reduce((options, ip, index) => {
				options[index] = ip;
				return options;
			}, {});

			Swal.fire({
				title: this.$t("dialogs.sdwan_remove_ip.title"),
				input: "select",
				inputOptions: ipOptions,
				inputPlaceholder: this.$t("dialogs.sdwan_remove_ip.inputPlaceholder"),
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#d33",
				cancelButtonColor: "#3085d6",
				confirmButtonText: this.$t("dialogs.sdwan_remove_ip.confirmButtonText"),
				cancelButtonText: this.$t("dialogs.sdwan_remove_ip.cancelButtonText"),
				inputValidator: (value) => {
					if (!value) {
						return this.$t("dialogs.sdwan_remove_ip.validation");
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					const selectedIp = ipAddresses[result.value];

					let userData = TokenService.getUser();
					Swal.fire({
						title: this.$t("dialogs.loading.title"),
						html: this.$t("dialogs.loading.content"),
						timerProgressBar: true,
						allowOutsideClick: false,
						backdrop: `rgba(130,0,160,0.4)`,
						didOpen: () => {
							Swal.showLoading();
							evoapi
								.post(`/customers/${userData.customerId}/sdwan/remove-ip`, {
									SWANContractID: parseInt(contract_id),
									IP: selectedIp, // Pass the selected IP to remove
								})
								.then((response) => {
									Swal.close(); // Chiudi la notifica di attesa

									Swal.fire(
										this.$t("responses.sdwan_remove_ip_success.title"),
										this.$t("responses.sdwan_remove_ip_success.content"),
										"success"
									);
									this.reloadTableData();
								})
								.catch((error) => {
									this.swalBasicErrorDialog();
								});
						},
					});
				}
			});
		},

		editTemplateDirectly(template_id) {
			let userData = TokenService.getUser();
			evoapi.get("/customers/" + userData.customerId + "/templates/" + template_id).then((response) => {
				this.templateModal.template_id = response.data.template_id;
				this.templateModal.title = response.data.name;
				this.templateModal.text = response.data.text;
				this.flgShowTemplateTextModal = true;
				this.$router.push({
					name: "Edit template",
					params: { template: this.templateModal },
				});
			});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		handleDelete(item) {
			this.swalWarningDialog(
				this.$t("dialogs.delete_sdwan_service_confirmation.title"),
				this.$t("dialogs.delete_sdwan_service_confirmation.content", { subject: item.description }),
				this.$t("dialogs.delete_sdwan_service_confirmation.ok")
			).then((result) => {
				if (result.value) {
					let userData = TokenService.getUser();
					this.swalShowLoader();
					evoapi
						.delete("/customers/" + userData.customerId + "/sdwan/config/" + item.contract_id)
						.then((response) => {
							this.deleteRow(item);
							this.filters.query = ""; // Resetta il filtro di ricerca
							this.swalSuccessDialog(
								this.$t("responses.deleted_success.title"),
								this.$t("responses.deleted_success.content", { subject: item.description })
							).then((result) => {
								if (result.value) {
									// Nuova dialog di conferma per rilasciare l'indirizzo Elastic IP
									this.swalWarningDialog(
										this.$t("dialogs.release_elastic_ip.title"),
										this.$t("dialogs.release_elastic_ip.content", {
											subject: item.sdwan_cidr_addr_str,
										}),
										this.$t("dialogs.release_elastic_ip.ok"),
										this.$t("dialogs.release_elastic_ip.cancel")
									).then((result) => {
										if (result.value) {
											// Chiamata all'endpoint per rilasciare l'Elastic IP per ogni indirizzo IP associato al contratto (in sdwan_cidr_addr)
											item.sdwan_cidr_addr.forEach((ip) => {
												// ora devo chiamare le api per recuperare l'id dell'elastic ip
												evoapi
													.get(
														`/customers/${userData.customerId}/elasticIp/getElasticIPIDFromIP/${ip}`
													)
													.then((response) => {
														evoapi
															.get(
																`/customers/${userData.customerId}/elasticIp/free/${response.data.result}`
															)
															.catch((error) => {
																this.swalBasicErrorDialog(error);
															});
													})
													.catch((error) => {
														this.swalBasicErrorDialog(error);
													});
											});
										}
									});
								}
							});
						})
						.catch(function (error) {
							this.swalBasicErrorDialog();
						});
				}
			});
		},

		/**
		 * @param contractID {number}
		 * @param tokenSSO {string}
		 */
		handleShield(contractID, tokenSSO) {
			if (tokenSSO) {
				// the device has a valid shield associated.
				this.swalDenyActionDialog(
					this.$t("uania.shield.active.modal.title"),
					null,
					this.$t("uania.shield.active.modal.confirmButton"),
					this.$t("uania.shield.active.modal.denyButton")
				)
					.then((swalResp) => {
						if (swalResp.isConfirmed) {
							this.handleEditShield(contractID);
						} else this.deleteShield(contractID);
					})
					.catch(() => this.swalBasicErrorDialog());
			} else {
				this.swalBasicChoiceDialog(
					this.$t("uania.shield.activation.modal.title"),
					this.$t("uania.shield.activation.modal.confirmButton")
				)
					.then((swalResp) => {
						if (swalResp.isConfirmed) this.handleActivateShield(contractID);
						else Swal.close();
					})
					.catch(() => this.swalBasicErrorDialog());
				// Ask to activate the shield
			}
		},

		handleEditShield(contractID) {
			let userData = TokenService.getUser();
			evoapi.get(`/customers/${userData.customerId}/${contractID}/uania/sso`).then((response) => {
				// print response
				this.tokenSSOUania = response.data.token_sso;

				// print in console.log tokenSSOUania
				let tokenSSOUania = this.tokenSSOUania;
				// console.log("Token SSO UANIA: ", tokenSSOUania);
				// this.$router.push({
				// 	name: "Uania Web Panel",
				// 	params: {
				// 		customerID: userData.customerId,
				// 		tokenSSOUania,
				// 	},
				// });
				let baseUrl = process.env.VUE_CLOUDTIK_SHIELD_URL ?? "https://shield.cloudtik.it";
				window.open(baseUrl + "/sso/" + tokenSSOUania);
			});
		},
		handleActivateShield(contractID) {
			this.swalShowLoader(this.$t("uania.shield.activation.waiting"));
			let userData = TokenService.getUser();
			evoapi
				.post(`/customers/${userData.customerId}/${contractID}/uania/shield`)
				.then(() => {
					this.swalSuccessDialog(
						this.$t("uania.shield.activation.success.title"),
						this.$t("uania.shield.activation.success.content")
					);
					this.reloadTableData();
				})
				.catch((err) => {
					// console.error(err);
					const errorTitleKey =
						err.response.status === 400 ? this.$t("uania.shield.activation.error.title") : null;
					this.swalBasicErrorDialog(errorTitleKey);
				});
		},
		deleteShield(contractID) {
			this.swalWarningDialog(this.$t("uania.shield.delete.alert_title"), null).then((res) => {
				if (!res.isConfirmed) return;
				this.swalShowLoader(this.$t("uania.shield.delete.waiting"));
				evoapi
					.delete(`/customers/${TokenService.getUser().customerId}/${contractID}/uania/shield`)
					.then(() => {
						this.swalSuccessDialog(this.$t("uania.shield.delete.success"));
						this.reloadTableData();
					})
					.catch(() => this.swalBasicErrorDialog());
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
			}
			this.reloadTableData();
		},
		reloadTableData() {
			return new Promise((resolve, reject) => {
				let userData = TokenService.getUser(); // Assicurati che questo sia il modo corretto per ottenere userData
				evoapi
					.get("/customers/" + userData.customerId + "/sdwan/config")
					.then(async (response) => {
						const statusPromises = response.data.map((item) => {
							return evoapi
								.get(`/customers/${userData.customerId}/sdwan/status/${item.contract_id}`)
								.then((statusResponse) => {
									item.sdwan_cidr_addr_str = "";
									for (let i = 0; i < item.sdwan_cidr_addr.length; i++) {
										item.sdwan_cidr_addr_str += item.sdwan_cidr_addr[i] + " ,";
									}
									item.sdwan_cidr_addr_str = item.sdwan_cidr_addr_str.slice(0, -1);
									return { ...item, status: statusResponse.data }; // Aggiunge lo status all'item
								})
								.catch((statusError) => {
									return { ...item, status: "Errore" }; // Gestisce gli errori restituendo un item con stato di errore
								});
						});

						// Attendi che tutte le promesse di status vengano risolte
						Promise.all(statusPromises)
							.then((updatedItems) => {
								this.tableData = updatedItems;
								this.searchedData = updatedItems;

								resolve();
							})
							.catch((error) => {
								reject(error);
							});
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		searchByQuery(results) {
			if (!results || this.filters.query === "") {
				return results;
			}
			if (this.$route.query.search) {
				// se è presente alla fine di this.filters.query ||threshold0, allora setta la variabile threshold a 0 e rimuovi il ||threshold0 da this.filters.query
				if (this.filters.query.endsWith("||threshold0")) {
					this.filters.query = this.filters.query.replace("||threshold0", "");
					this.fuseSearch = new Fuse(results, {
						keys: ["contract_id"],
						threshold: 0,
					});
				} else {
					this.fuseSearch = new Fuse(results, {
						keys: ["contract_id", "description", "sdwan_cidr"],
						threshold: 0.3,
						ignoreLocation: true,
						shouldSort: true,
					});
				}
			} else {
				this.fuseSearch = new Fuse(results, {
					keys: ["contract_id", "description", "sdwan_cidr"],
					threshold: 0.3,
					ignoreLocation: true,
					shouldSort: true,
				});
			}
			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		handleRedeploy(contractId) {
			this.swalWarningDialog(
				this.$t("dialogs.redeploy_sdwan_confirmation.title"),
				this.$t("dialogs.redeploy_sdwan_confirmation.content"),
				this.$t("dialogs.redeploy_sdwan_confirmation.ok"),
				this.$t("dialogs.redeploy_sdwan_confirmation.cancel")
			).then((result) => {
				if (result.value) {
					let userData = TokenService.getUser();
					this.swalShowLoader(this.$t("dialogs.redeploy_sdwan_confirmation.content"));
					evoapi
						.post(`/customers/${userData.customerId}/sdwan/config/${contractId}/redeploy`)
						.then((response) => {
							this.swalSuccessDialog(
								this.$t("responses.redeploy_success.title"),
								this.$t("responses.redeploy_success.content")
							);
						})
						.catch((error) => {
							this.swalBasicErrorDialog();
						});
				}
			});
		},
	},
	async mounted() {
		// Caricamento iniziale dei dati
		await this.reloadTableData();
		// Verifica se c'è un parametro di ricerca nell'URL e applicalo
		if (this.$route.query.search) {
			this.filters.query = this.$route.query.search + "||threshold0";
		}
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;
				// Fuse search initialization.
				results = this.searchByQuery(results);
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style lang="css" scoped>
.md-table-head {
	text-align: center;
	font-size: 20px;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.md-table-cell {
	border-right: 1px solid #ccc; /* Aggiungi un bordo a destra di ogni cella */
	text-align: center; /* Assicurati che il testo sia centrato */
}

.md-table-cell:last-child {
	border-right: none; /* Rimuovi il bordo a destra dell'ultima cella */
}

.md-table-head {
	text-align: center;
	font-size: 20px;
	border-right: 1px solid #ccc; /* Aggiungi un bordo anche agli header */
}

.md-table-head:last-child {
	border-right: none;
}

/* Assicurati che le righe siano ben separate */
.md-table-row {
	border-bottom: 1px solid #ccc;
}

/* Stile per i contenitori delle celle e delle intestazioni */
.centered-content {
	text-align: center;
}

/* Stile per le intestazioni della tabella */
.centered-header th {
	text-align: center !important;
}
.md-table-head {
	background-color: #f0f0f0; /* Grigio chiaro */
	text-align: center;
	font-size: 20px;
	border-right: 1px solid #ccc;
}
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.modal-code {
	text-align: left;
	font-family: "Courier New", Courier, monospace;
	font-size: 12px;
	overflow: scroll;
	height: 500px;
}

code:hover {
	font-weight: bold;
	text-decoration: underline;
	font-size: 105%;
	color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	background-color: #007bff; /* un leggero grigio come esempio, personalizza a tuo piacimento */
	background: linear-gradient(45deg, #006ba6, #d5419e);
}
code {
	border-radius: 4px;
	transition: all 0.3s; /* 0.3 secondi come esempio, personalizza a tuo piacimento */
	cursor: pointer;
}

.status-ok {
	height: 10px;
	width: 10px;
	background-color: green;
	border-radius: 50%;
	display: inline-block;
}
.status-warning {
	height: 10px;
	width: 10px;
	background-color: orange;
	border-radius: 50%;
	display: inline-block;
}
.status-ko {
	height: 10px;
	width: 10px;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
}
@media only screen and (min-width: 1281px) {
	.hide-on-large {
		display: none !important;
	}
}
.md-button.spacing-both {
	margin-left: 5px;
	margin-right: 5px;
}
</style>
