import Swal from "sweetalert2";

export const swalUtils = {
	methods: {
		/**
		 * @description Show the generic modal when an unhandled error occurs.
		 * @return Promise
		 */
		swalBasicErrorDialog(title = null) {
			if (!title) title = this.$t("responses.error.title");
			return Swal.fire({
				icon: "error",
				title,
				text: this.$t("responses.error.content"),
				footer: this.$t("responses.error.footer"),
			});
		},
		/**
		 * @description Show the basic modal with a dual choice. Confirm or cancel
		 * @param title {string}
		 * @param confirmButtonText {string}
		 * @return Promise
		 */
		swalBasicChoiceDialog(title, confirmButtonText) {
			return Swal.fire({
				title,
				showCancelButton: true,
				confirmButtonText,
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonClass: "button-warning",
			});
		},

		/**
		 *
		 * @param title {string}
		 * @param html {string}
		 * @param confirmButtonText {string}
		 * @param denyButtonText {string}
		 * @return {Promise}
		 */
		swalDenyActionDialog(title, html, confirmButtonText, denyButtonText) {
			return Swal.fire({
				title,
				html,
				confirmButtonText,
				denyButtonText,
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonClass: "button-warning",
				showCancelButton: true,
				showDenyButton: true,
			});
		},
		/**
		 * @description Show a loading spinner inside a Swal
		 * @param title {string|null} If left null is then converted in the default string
		 * @return Promise
		 */
		swalShowLoader(title = null) {
			if (!title) title = this.$t("dialogs.delete_sdwan_confirmation_progress.title");
			Swal.fire({
				title,
				html: this.$t("dialogs.delete_sdwan_confirmation_progress.content"),
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
		},

		/**
		 * @description Shows a warning dialog.
		 * @param title {string}
		 * @param html {string}
		 * @param confirmButtonText {string}
		 * @param cancelButtonText {string}
		 * @return {Promise}
		 */
		swalWarningDialog(title, html, confirmButtonText, cancelButtonText = null) {
			if (!cancelButtonText) cancelButtonText = this.$t("general.cancel");
			return Swal.fire({
				title,
				html,
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success btn-fill",
				cancelButtonClass: "md-button md-danger btn-fill",
				cancelButtonText,
				confirmButtonText,
				buttonsStyling: false,
			});
		},

		/**
		 * @description basic success dialog with a confermation button and click outside allowed
		 * @param title {string}
		 * @param text {string}
		 * @return {Promise}
		 */
		swalSuccessDialog(title, text) {
			return Swal.fire({
				title,
				text,
				type: "success",
				confirmButtonClass: "md-button md-success btn-fill",
				buttonsStyling: false,
			});
		},
	},
};
